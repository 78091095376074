import chunk from './chunk';
import compact from './compact';
import concat from './concat';
import difference from './difference';
import differenceBy from './differenceBy';
import differenceWith from './differenceWith';
import drop from './drop';
import dropRight from './dropRight';
import dropRightWhile from './dropRightWhile';
import dropWhile from './dropWhile';
import fill from './fill';
import findIndex from './findIndex';
import findLastIndex from './findLastIndex';
import first from './first';
import flatten from './flatten';
import flattenDeep from './flattenDeep';
import flattenDepth from './flattenDepth';
import fromPairs from './fromPairs';
import head from './head';
import indexOf from './indexOf';
import initial from './initial';
import intersection from './intersection';
import intersectionBy from './intersectionBy';
import intersectionWith from './intersectionWith';
import join from './join';
import last from './last';
import lastIndexOf from './lastIndexOf';
import nth from './nth';
import pull from './pull';
import pullAll from './pullAll';
import pullAllBy from './pullAllBy';
import pullAllWith from './pullAllWith';
import pullAt from './pullAt';
import remove from './remove';
import reverse from './reverse';
import slice from './slice';
import sortedIndex from './sortedIndex';
import sortedIndexBy from './sortedIndexBy';
import sortedIndexOf from './sortedIndexOf';
import sortedLastIndex from './sortedLastIndex';
import sortedLastIndexBy from './sortedLastIndexBy';
import sortedLastIndexOf from './sortedLastIndexOf';
import sortedUniq from './sortedUniq';
import sortedUniqBy from './sortedUniqBy';
import tail from './tail';
import take from './take';
import takeRight from './takeRight';
import takeRightWhile from './takeRightWhile';
import takeWhile from './takeWhile';
import union from './union';
import unionBy from './unionBy';
import unionWith from './unionWith';
import uniq from './uniq';
import uniqBy from './uniqBy';
import uniqWith from './uniqWith';
import unzip from './unzip';
import unzipWith from './unzipWith';
import without from './without';
import xor from './xor';
import xorBy from './xorBy';
import xorWith from './xorWith';
import zip from './zip';
import zipObject from './zipObject';
import zipObjectDeep from './zipObjectDeep';
import zipWith from './zipWith';

export default {
  chunk, compact, concat, difference, differenceBy,
  differenceWith, drop, dropRight, dropRightWhile, dropWhile,
  fill, findIndex, findLastIndex, first, flatten,
  flattenDeep, flattenDepth, fromPairs, head, indexOf,
  initial, intersection, intersectionBy, intersectionWith, join,
  last, lastIndexOf, nth, pull, pullAll,
  pullAllBy, pullAllWith, pullAt, remove, reverse,
  slice, sortedIndex, sortedIndexBy, sortedIndexOf, sortedLastIndex,
  sortedLastIndexBy, sortedLastIndexOf, sortedUniq, sortedUniqBy, tail,
  take, takeRight, takeRightWhile, takeWhile, union,
  unionBy, unionWith, uniq, uniqBy, uniqWith,
  unzip, unzipWith, without, xor, xorBy,
  xorWith, zip, zipObject, zipObjectDeep, zipWith
};
